
import React from "react";
import data from "./Data.js"
import Card from "./components/Card";

const CardLocal = () => {
    return (
        <section id="geolocal" className="card_local py-lg-5 container"  >
            <h2 className="text-center mt-5" style={{ fontFamily: 'Dancing Script', fontSize: '40px' }}>Unde și Când?</h2>
            <div className="row justify-content-center "  >

                {data.cardData.map((item, index) => {
                    return (
                        <Card key={index} img={item.img} title={item.title} name={item.name} data={item.data} ora={item.ora} ora1={item.ora1} adress={item.adress} harta={item.harta} iframe={item.iframe} />
                    )
                })}


            </div>
        </section>


    )


}

export default CardLocal;