import React from "react"
import "../css/ImgInvitatie.css"
import logouMembru from "../images/8.png"



const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5 mb-5" >
            <h1>Invitație Partener</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text mb-5">
                            <h4>Stimate Partener,</h4>
                            <h5>A devenit deja o tradiție, <br />
                                ca la începutul fiecărui an agricol, <br />
                                Cooperativa de Întreprinzător ”Agrostoc” <br />
                                să desfășoare
                            </h5>
                            <h4>Adunarea Generală a Membrilor.</h4>
                          {/*<img id="imgm" src={logouMembru}/> */}  
                            <h5>
                                Prin acest eveniment ne propunem <br />
                                să punctăm realizările ce au fost marcate <br />
                                pe parcursul celor <b>20 de ani</b>  de activitate a C.Î. ”Agrostoc”
                            </h5>
                            <div className="row">
                                <h5 >
                                    <b><i>Cu gratitudine,</i></b>
                                </h5>
                                <div className="col col-lg-6 col-md-6 col-sm-12">
                                    <h5>
                                     <b>Alexandru Smutin</b>    <br />
                                        Președinte al Consiliului Administrativ
                                    </h5>
                                </div>
                                <div className="col col-lg-6 col-md-6 col-sm-12">
                                    <h5>
                                      <b>Igor Tagadiuc</b>  <br />
                                        Președinte al C.Î. ”Agrostoc”
                                    </h5>
                                </div>
                            </div>
                            <br/>

<h5>
    Evenimentul va avea loc joi,
</h5>
<h4>
    9 Iunie 2022, ora 17:30
</h4>
<h5>
    Restaurantul
</h5>
<h4>
"Grand Elysée"
</h4>
<h5>
Strada Chișinăului 45, Sîngera.
</h5>


                        </div>










                        <h4 class="card-footer">Vă așteptăm cu drag!</h4>

                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;