import React from 'react';
import {Helmet} from "react-helmet";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

import Header from './components/Header';
import Home from './components/Home';
import FooterB from './components/Footer';
import './css/Body.css';
import data from './Data';


function App() {
  return (
    <>
<Helmet>
                <meta charSet="utf-8" />
                <title>Invitatie Partener - Agrostoc</title>
                <meta name="description" content="Invitatie Online - Partener" />
            </Helmet>

 {data.introData.map((item, index) => {
                    return (
                      <Header mire={item.mire} mireasa={item.mireasa} logo={item.logo}/> 
                    )
                })}

     
     
     <Home/>
    
     <FooterB/>

    
    </>

   
  );
}

export default App;
